import "./style.scss";

import { Flex, Layout } from "antd";
import { ShippingStatus, StoreOwner } from "./Slice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import SummaryComponent from "./components/SummaryComponent";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { getStoresTracking } from "./services/store-tracking";
import { StoreContext } from "../../shared/context/provider";
import { useContext, useEffect } from "react";
import LoadingComponent from "./components/LoadingComponent";

export default function StoreTracking() {
  const { tracking_number } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const storeContext = useContext(StoreContext);

  const { data, error, isLoading, isFetching } = useQuery<StoreOwner, AxiosError>({
    queryKey: ["storeTracking"],
    queryFn: () => getStoresTracking(tracking_number as string, searchParams.get("company") as string),
    refetchInterval: 2 * (1000 * 60),
  });

  if (error && error.response) {
    const httpStatus = error.response.status;
    if (httpStatus === 404) {
      navigate("/error?status=404");
    } else {
      navigate("/error?status=500");
    }
  }

  useEffect(() => {
    if (data) {
      storeContext?.setStoreDetail({
        name: data.store_name,
        number: data.store_number,
      });
    }
  }, [data]);

  const renderFilteredShippingInfo = (title: string, filteredStatues: ShippingStatus[]) => {
    const infos = data?.shipping_infos?.filter((info) => filteredStatues.includes(info.status)) || [];
    return (
      infos.length > 0 && (
        <div key={title}>
          <h3 className="shipping-title">{title}</h3>
          <SummaryComponent data={infos} />
        </div>
      )
    );
  };

  return (
    <>
      {isLoading || isFetching ? (
        <LoadingComponent />
      ) : (
        <Layout className="wrap-content content-tracking">
          <Flex vertical gap={16}>
            {renderFilteredShippingInfo("รายการรับสินค้าวันนี้", [
              ShippingStatus.waiting,
              ShippingStatus.inTransit,
            ])}
            {renderFilteredShippingInfo("รายการที่ยกเลิก", [
              ShippingStatus.canceled,
              ShippingStatus.exception,
            ])}
            {renderFilteredShippingInfo("รายการที่จัดส่งสําเร็จ", [ShippingStatus.success])}
          </Flex>
        </Layout>
      )}
    </>
  );
}
